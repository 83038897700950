import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const Atlantique = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="What Mati Diop & Lil Wayne Share" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> What Mati Diop & Lil Wayne Share</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Feb. 23rd, 2020) </p>
        </div>
        <div className="articleBody">
        <p>
        <i>Warning!</i> This article contains spoilers. If you haven't seen <i>Atlantique</i> (2019) dir. Mati Diop, come back when you have.        </p>
        <p>
        So boom, this movie is easily a masterpiece. Diop's writing and directing, Mathon's cinematography, the acting from each of the three leads, especially Amadou Mbow as Issa: the whole shit is just a buffet, but not the kind that's gon leave you w/ the bubble-guts cuz you mixed the General Tso's with the tikka masala. I think maybe a better word to describe it is 'symphonic', cuz it really is that.
        </p>
        <p>
        I was lucky enough to see it on the big screen at this past <a href="https://indiememphis.com/">Indie Memphis</a> (word to the everyone involved in the Black Creator's Forum), and while I was gripped by it the whole way through, the part that really stunned me comes toward the end, when the boys, possessing the girls, come back to the beach-side club house. The use of mirrors in this section is brilliant -- the way the women actors echo the postures of their male counterparts makes for a gorgeous sequence of tableaus, amplified to utterly moving heights by the narrative context in which it appears.
        </p>
        <p>

And like any great movie, <i>Atlantique</i> kept me predicting without becoming predictable, but it was during this sequence (and through til the ending) that I wished, for the first time, that my prediction would have come true…. So here -- humbly, cuz I can't overstate how great I find this movie -- is my only gripe with, and alternative ending to, <i>Atlantique</i> (and naw this don't count as fanfiction, I'm not no fucking dweeb).
        </p>
        <p>
        When it became clear that the lost boys appear as themselves in mirrors, I was convinced that the reunion between Ada and Souleiman would involve Souleiman's ghost possessing Ada's body, and that we would get a closing shot of Ada looking into the mirror and seeing her lost love staring back at her. I thought they'd share a touch through the mirror, and even a kiss wouldn't have un-suspended my disbelief. I thought that would be subtle, artful, beautiful.
        </p>
        <p>
        But instead, what did we get? Well if you've seen it you know what we got, and herein lies my dissatisfaction: I am fundamentally opposed to any cop, under any conceivable circumstance (supernatural, or otherwise), in any country on Earth, getting any type of cheeks, ever!!! I can understand Ada fucking on a ghost, but fucking on a cop?! They simply never deserve to enjoy the pleasures of the flesh.
        </p>
        <p>
        And so the other night I was out and about, being my usual wallflower, and guess what song the DJ put on? That's right, "Mrs. Officer" by Lil Wayne Ft. Bobby Valentino. And that's when I realized that this song that I've been singing along to since a pup is despicable pig propaganda. It's also, I think, the closest musical parallel to <i>Atlantique</i>. Just imagine my face in the club when this clicked. I looked like that meme of Wee-Bey from <i>The Wire</i>.
        </p>
        <p>
        So yeah, it's impressive how Diop can pull off something so fantastical with such minimalism, without relying on VFX to vacuum us into the screen. It's impressive how Lil Wayne can make the whole club sing 'wee-oo wee-oo wee' unabashedly, without a hint of self-consciousness. But ultimately, and completely, it's fuck the cops.
        </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default Atlantique;
